import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
//import { Table } from '../common/Table/Table'
import { Button, Dropdown, Menu, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table'
import { TEnumItem, formatIsoToDate, formatIsoToTime } from '../helpers/utils'
import { DownOutlined } from '@ant-design/icons';
import SettingsPageStore from '../../store/settingsPage/SettingsStore';
import { HeadTitle } from '../common/HeadTitle/PageTitle';
import { ApplicationsStore } from '../../store/applications/Applications';
import { ModalSettings } from './component/Modal';
const s = require('./styles.module.scss')



interface ISettingsProps {

}

export const Settings: React.FC<ISettingsProps> = observer(({ }) => {

    const [openPopup, setOpenPopup] = React.useState<boolean>(false)
    const [arrData, setArrayData] = useState([])
    const [openModal, setOpenModal] = useState({open: false, flag:''})
    const [territoryInfo, setTerritoryInfo] = useState()

    //Для таблицы
    const scroll: { x?: number | string; y?: number | string } = { x: 1000, y: 700 };
    const defaultTitle = () => ' Настройки маршрутизации исполнителя';
    const [page, setPage] = useState<number>(1)
    const [pageSize, setPageSize] = useState<number>(25)

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [mobile, setMobile] = useState(windowWidth > 800 ? false : true)

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
        windowWidth > 800 ? setMobile(false) : setMobile(true)
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth, mobile]);

    const {
        settings,
        settingsLoading,
        deleteSettings,
        requestSettings,
        editSettings,
        createSettings,
        requestSources,
        requestUsers,
        users,
        sources
    } = SettingsPageStore

    const {
        requestSelPults,
        pults
    } = ApplicationsStore


    const requestSettingsHandler = () => {
        requestSettings().then((res) => {
        
        })
        requestSelPults()
        requestSources()
        requestUsers({flag: "performers"})
    }

    const updateRequest = () => {
        requestSettings()
    }

    useEffect(() => {
        requestSettingsHandler()
    }, [])



    const items = [
        {
            key: '1',
            label: 'Редактировать',
            onClick: () => { setOpenModal({open: true, flag: 'post'}) },
        },
        {
            key: '2',
            label: 'Удалить',
            onClick: () => { setOpenModal({open: true, flag: 'delete'}) },
        },
    ];

    const menu = (
        <Menu>
          {items.map(item => (
            <Menu.Item key={item.key} onClick={item.onClick}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      );
    const columns = [
        {
            title: "ID",
            dataIndex: 'id',
            key: 'id',
            width:100,
        },
        {
            title: 'Панель',
            dataIndex: 'panel',
            key: 'panel',
            width: 100,
        },
        {
            title: 'Исполнитель',
            dataIndex: 'performerName',
            key: 'performerName',
            width: 100,
        },
        {
            title: 'Источник',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 100,
        },
    ]

    const data: any[] = settings?.data?.map((element: any) => {
        return (
            {   
                "id": element.id,
                "panel": element.panel,
                "performerName": element.performer.name,
                "sourceName": element.source.name,
                "sourceId": element.idSource,
                "idPerformer":element.idPerformer
            }
        )
    })
    // console.log(settings)
    console.log(sources)
    console.log(users)
    console.log(pults)
    
    return (
    <>
    <ModalSettings
    title={''}
    flag={openModal.flag}
    open={openModal.open}
    sorces = {sources?.data}
    users = {users?.data?.value}
    pults = {pults}
    data = {territoryInfo}
    closeHandler={() => setOpenModal({open: false, flag: ''})}
    
    requestHandler={updateRequest}/>
    <div className={s.main_page_wrap}>
            <div className={s.title_wrap}>
                <HeadTitle title={'Настройки'} />
            </div>
        <div className={s.items}>

        
                <div className={s.title_content}>

                        <div className={s.buttons}>
                                <div
                                    className={s.findApplication}
                                    onClick={ () => { setOpenModal({open: true, flag: 'put'}) }}>
                                    Добавить
                                </div>
                        </div>
                    <Table
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => {
                                    setTerritoryInfo(record)
                                }
                            }
                        }}
                        size='large'
                        rowClassName={s.tableRow}
                        columns={columns && [...columns.map(element => {
                            return({
                                ...element
                            })
                        }), {
                            title: 'Действия',
                            key: 'action',
                            align: 'center',
                            render: (_, record) => {
                                return (
                                    <Dropdown
                                    overlay={menu}
                                    >
                                    <a className="text-primary text-decoration-none">
                                        Действия <DownOutlined />
                                    </a>
                                    </Dropdown>
                        )
                    },
                    width: 100
                        }]}
                        dataSource={data}
                        bordered
                        title={defaultTitle}
                        scroll={scroll}
                        loading={settingsLoading}
                        pagination={false}
                    />
                </div>
                </div>
    </div>
    </>
    )
})